.App {
  text-align: center; 
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: aliceblue;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-Header {
  background-color: #282c34;
  min-height: 100vh;
  height: 100px;
  display: grid;
  grid-template-areas: "nav";
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Main {
  background-color: #d2dbee;
  text-align: center;
  position: relative;
  color: black;
}

/* Now set the height of your Footer equal to the padding that you left for it in your #root.  To make it stay at the bottom, you will set it to be absolutely positioned.  If Main grows larger, your Footer will move down to remain at the bottom of the page. */ 
.Footer {
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.Nav {
  grid-area: nav;
  display: grid;
  grid-template-columns: repeat(6,auto);
  align-items: center;
  justify-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#content {
  padding-top: 5px;
  min-height: calc(100vh - 92px);
}

.round-corners-15px{
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;  
  border: 3px solid orangered;
}
.round-corners-10px{
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid black;
  padding: 50px;
}
.round-corners-8px{
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 2px solid black;
  padding: 20px;
}
.unround-top-corners{
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.unround-bottom-corners{
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.regularEvents{
  background: rgb(241, 210, 151) !important;
  font-size: calc(10px + 0.5vmin);
}

.specialEvents{
  background: rgb(241,210,151) !important;
  font-size: calc(10px + 0.5vmin);
}

.importantDates{
  background: rgb(241,210,151) !important;
  font-size: calc(10px + 0.5vmin);
}
.mainimportantEvent{
  background: rgb(223, 160, 65) !important;
  font-size: calc(10px + 0.5vmin);
}

.courselitemcenter{
  margin: 0;
position: absolute;
top: 50%;
left: 50%;
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
